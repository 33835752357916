<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-card width="600">
        <v-toolbar dense dark class="secondary">
          <v-toolbar-title>Clients summary guide</v-toolbar-title>
        </v-toolbar>
        <v-progress-linear style="position: absolute" v-show="loading" :indeterminate="true" class="ma-0"></v-progress-linear>
        <v-card-text>
          <table class="v-datatable v-table theme--light print-font-small bill">
            <thead>
              <tr style="height: auto">
                <th class="text-xs-left py-0">Fullname</th>
                <th class="text-xs-left py-0">Medicaid</th>
                <!-- <th class="text-xs-left py-0">DOB</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="i in clients" :key="'clientid' + i.clientId" :class="{ 'red lighten-5': !i.active }">
                <td>
                  <strong>{{ i.firstname }} {{ i.lastname }}</strong>
                </td>
                <td>
                  <strong>{{ i.memberNo }}</strong>
                </td>
                <!-- <td>{{ i.dob | moment("utc", "MM/DD/YYYY") }}</td> -->
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import clientApi from "@/services/api/ClientServices";

export default {
  data() {
    return {
      loading: false,
      clients: []
    };
  },

  mounted() {
    this.$store.commit("SET_ACTIVE_CLIENT", 0);
    this.loadClients();
  },

  methods: {
    async loadClients() {
      this.clients = [];
      this.loading = true;
      try {
        let clients = await clientApi.getClients();
        this.clients = clients.filter((s) => s.active);
      } catch (error) {
        console.log(error);
        this.$toast.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
